<!--Vista de checkout de eventos-->
<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12" md="10" lg="9">
        <v-row>
          <v-col cols="12" class="pt-10">
            <v-img v-if="msConfig.banner" max-height="250" class="rounded-xl" :src="msConfig.banner"></v-img>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <p class="display-1 text-center font-weight-bold">
              Comprar membresía
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card class="px-3 pt-6 pb-2" rounded="xl" elevation="0"
              :style="`border: 1.7px solid ${msConfig.primaryColor}`">
              <v-row class="px-5 px-md-15">
                <!--INICIO de Paso 1 de Checkout-->
                <v-col v-if="currentStep === 1" cols="12">
                  <v-row>
                    <v-col cols="12" class="pb-0 pt-10">
                      <p class="subtitle-1 mb-7">
                        <span class="font-weight-bold mr-4">
                          Paso 1 de {{ user.role === "MEMBER" ? 3 : 4 }}
                        </span>
                        <span>
                          Selecciona el nivel de membresía que deseas adquirir:
                        </span>
                      </p>
                    </v-col>
                    <v-radio-group v-model="affiliationLevels.selected.id" style="width: 100%">
                      <v-col cols="12" class="access-item-border" v-for="level in affiliationLevels.levels"
                        :key="level.id">
                        <v-row>
                          <v-col cols="12" md="6" class="py-5 px-5 pl-md-10">
                            <p class="title font-weight-bold">
                              {{ level.name }}
                            </p>
                            <p class="body-2">{{ level.details }}</p>
                            <p class="subtitle-2 mb-0">
                              {{ level.availableAffiliationTypesForSale }}
                              accesos disponibles.
                            </p>
                          </v-col>
                          <v-col cols="12" md="3" xl="3" offset-md="1" class="py-5 px-5">
                            <p class="title font-weight-bold mb-0">Precio:</p>
                            <p class="subtitle-1">{{ level.formattedPrice }}</p>
                          </v-col>
                          <v-col cols="12" md="2" xl="2" class="d-flex align-center justify-center">
                            <v-radio color="accent" :value="level.id" :disabled="!level.availableAffiliationTypesForSale
                              " @change="handleRadioChange(level)" />
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-radio-group>
                    <v-col cols="12" class="mt-5">
                      <v-row align="center">
                        <v-col cols="12" md="6" lg="4">
                          <v-tooltip top :disabled="!ticketsToBuyIsZero">
                            <template v-slot:activator="{ on }">
                              <v-row v-on="on">
                                <v-text-field class="mr-2" v-model="coupon" :disabled="!isAffiliationLevelSelected || couponApplied
                                  " :error="errors.coupon.error" :error-messages="errors.coupon.message"
                                  :success="success.coupon.success" :success-messages="success.coupon.message"
                                  label="Cupón/Cortesía" dense filled rounded prepend-inner-icon="fas fa-tags" />
                              </v-row>
                            </template>
                            <span>
                              Para poder ingresar un(a) cupón/cortesía es
                              necesario seleccionar un boleto.
                            </span>
                          </v-tooltip>
                        </v-col>
                        <v-col>
                          <v-btn :disabled="!isAffiliationLevelSelected ||
                            !coupon ||
                            couponApplied
                            " color="secondary" small @click="addCoupon">
                            Aplicar
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="6" class="my-7">
                      <v-row>
                        <v-btn class="mx-1" color="primary" text rounded
                          :to="{ name: 'microsite', params: { affiliationId } }">
                          <span class="font-weight-bold">Cancelar</span>
                        </v-btn>
                        <v-btn class="mx-1" :color="msConfig.buttonColor" :style="`color: ${msConfig.textButtonColor}`"
                          rounded :disabled="!isAffiliationLevelSelected" @click="increaseStep">
                          <span class="font-weight-bold"> Continuar </span>
                        </v-btn>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="6" class="d-flex justify-end align-end pb-7">
                      <p class="headline mb-0" v-if="order.itemId != null">
                        <span class="font-weight-bold">Total: </span>{{
                          totalPurchaseWithDiscount.number == 0
                          ? "Gratis"
                          : totalPurchaseWithDiscount.string
                        }}
                      </p>
                    </v-col>
                  </v-row>
                </v-col>
                <!--FIN de Paso 1 de Checkout-->
                <!--INICIO de Paso 2 de Checkout-->
                <v-col v-if="currentStep === 2" cols="12">
                  <v-form ref="affiliationLevelForm">
                    <v-row>
                      <v-col cols="12" class="pb-0 pt-10">
                        <p class="subtitle-1 mb-7">
                          <span class="font-weight-bold mr-4">
                            Paso 2 de 4
                          </span>
                          <span> Ingresa los datos de asistente </span>
                        </p>
                      </v-col>
                      <!--INICIO de v-expansion-panels para poner los datos de asistente-->
                      <v-col cols="12 pt-0 mb-5">
                        <v-row>
                          <v-col cols="12">
                            <p class="title font-weight-bold mb-0">
                              {{ affiliationLevels.selected.name }}
                            </p>
                          </v-col>
                          <v-col cols="12">
                            <p class="font-weight-bold">
                              <i class="fas fa-exclamation-circle"></i>
                              Verifica que tu email sea correcto, es ahí donde
                              enviaremos tu confirmación de registro.
                            </p>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field v-model="affiliateInformation.name" label="Nombre(s)"
                              :rules="fieldsValidations.name" filled rounded></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field v-model="affiliateInformation.lastName" label="Apellidos"
                              :rules="fieldsValidations.lastName" filled rounded></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field v-model="affiliateInformation.email" label="E-mail"
                              :rules="fieldsValidations.email" @keydown="formatEmail(affiliateInformation.email)" filled
                              rounded></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field v-model="affiliateInformation.confirmEmail" :rules="[
                              (affiliateInformation.email ===
                                affiliateInformation.confirmEmail &&
                                affiliateInformation.email !== '' &&
                                affiliateInformation.confirmEmail !== '') ||
                              'La confirmación de e-mail no es correcta',
                            ]" @keydown="
  formatEmail(affiliateInformation.confirmEmail)
  " label="Confirmar e-mail" required filled rounded></v-text-field>
                          </v-col>
                          <!-- EXTRA FIELDS -->
                          <template v-if="affiliationLevels.selected.registrationFields
                              .length > 0
                            ">
                            <v-col cols="12" md="6" v-for="field in affiliationLevels.selected
                              .registrationFields" :key="field._id">
                              <v-text-field v-if="field.fieldType === 'TEXT'" v-model="affiliateInformation.registrationFields[
                                field.formattedName
                                ]
                                " :rules="field.required
      ? fieldsValidations.required
      : []
    " :label="field.name" :hint="field.description" filled rounded />
                              <v-select v-if="field.fieldType === 'LIST'" v-model="affiliateInformation.registrationFields[
                                field.formattedName
                                ]
                                " :rules="field.required
      ? fieldsValidations.required
      : []
    " :menu-props="{
    zIndex: 100,
    top: true,
    offsetY: true,
  }" :label="field.name" :hint="field.description" :items="field.options" filled
                                rounded />
                            </v-col>
                          </template>
                        </v-row>
                      </v-col>
                      <!--FIN de v-expansion-panels para poner los datos de asistente-->
                      <v-col cols="12" md="6" class="my-7">
                        <v-row>
                          <v-btn class="mx-1" color="primary" text rounded @click="currentStep--"><span
                              class="font-weight-bold">Regresar</span></v-btn>
                          <v-btn class="mx-1" :color="msConfig.buttonColor" :style="`color: ${msConfig.textButtonColor}`"
                            rounded @click="setAffiliateInformation">
                            <span class="font-weight-bold">Continuar</span>
                          </v-btn>
                        </v-row>
                      </v-col>
                      <v-col cols="12" md="6" class="d-flex justify-end align-end pb-7">
                        <p class="headline mb-0">
                          <b>Total: </b>{{
                            totalPurchaseWithDiscount.number == 0
                            ? "Gratis"
                            : totalPurchaseWithDiscount.string
                          }}
                        </p>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-col>
                <!--FIN de Paso 2 de Checkout-->
                <!--INICIO de Paso 3 de Checkout-->
                <v-col v-if="currentStep === 3" cols="12">
                  <v-row>
                    <v-col cols="12" class="pb-0 pt-10">
                      <p class="subtitle-1 mb-7">
                        <span class="font-weight-bold mr-4">Paso {{ user.role === "MEMBER" ? 2 : 3 }} de
                          {{ user.role === "MEMBER" ? 3 : 4 }} </span><span>Selecciona el método de pago</span>
                      </p>
                    </v-col>
                    <v-col cols="12">
                      <v-row>
                        <v-col cols="12" class="pt-0">
                          <v-item-group v-model="order.paymentInformation.paymentMethod">
                            <v-row justify="center">
                              <v-col cols="12">
                                <h2 class="title font-weight-bold">
                                  Métodos de pago
                                </h2>
                              </v-col>
                              <template v-if="paymentMethods.length > 0 &&
                                activePaymentMethods
                                ">
                                <v-col cols="12" sm="6" md="4" v-for="(item, index) in paymentMethods" :key="index">
                                  <v-item v-if="item.name !== 'BANK_TRANSFER'
                                      ? item.active
                                      : item.name === 'BANK_TRANSFER' &&
                                      (hasStripeConfig || user.stripeAccountType == 'EXPRESS')
                                    " v-slot="{ active, toggle }" :value="item.name">
                                    <v-card :color="active ? msConfig.buttonColor : 'white'
                                      " class="pa-2 px-lg-3 py-lg-5 fill-height text-center" :style="`color: ${active ? msConfig.textButtonColor : ''
    }`" @click="
    paymentSelection(
      toggle,
      item.name,
      active
    )
    " rounded="sm" elevation="1">
                                      <v-row justify="center" align="center" class="fill-height">
                                        <v-col cols="12">
                                          <span class="title font-weight-bold mb-5 break-word">
                                            {{ paymentLabels[item.name] }}
                                          </span>
                                          <br />
                                          <v-icon :style="getSizePaymentsMethodsIcons" :color="active
                                              ? msConfig.textButtonColor
                                              : ''
                                            ">{{ item.icon }}</v-icon>
                                        </v-col>
                                      </v-row>
                                    </v-card>
                                  </v-item>
                                </v-col>
                              </template>
                              <v-col v-else>
                                <h3 class="red--text text-center title font-weight-bold">
                                  No se encontraron métodos de pago
                                </h3>
                              </v-col>
                            </v-row>
                          </v-item-group>
                        </v-col>

                        <v-col v-if="order.paymentInformation.paymentMethod ===
                          paymentMethodsKeys.Cash
                          " cols="12" class="pb-0">
                          <p class="subtitle-1 primary--text font-weight-bold">
                            <v-icon color="amber">mdi-alert</v-icon> Los pagos
                            en OXXO se ven reflejados 24 horas después de haber
                            realizado el pago.
                            <!-- por lo que recomendamos hacer el
                            pago a mas tardar 24 horas antes de que comience el
                            evento. -->
                          </p>
                          <!--<p class="subtitle-1">Los pagos en oxxo se ven reflejados 24 horas después de haber realizado el pago, por lo que recomendamos hacer el pago, a mas tardar 24 horas antes de que comience el evento.</p>-->
                        </v-col>
                        <v-col v-if="order.paymentInformation.paymentMethod ===
                          paymentMethodsKeys.BankTransfer
                          " cols="12" class="pb-0">
                          <p class="subtitle-1 primary--text font-weight-bold">
                            <v-icon color="amber">mdi-alert</v-icon> Los pagos
                            mediante transferencia bancaria se ven reflejados 24
                            horas después de haber realizado el pago.
                            <!-- por lo que
                            recomendamos hacer el pago a mas tardar 24 horas
                            antes de que comience el evento.-->
                          </p>
                          <p class="subtitle-1  font-weight-bold " style="color: red;">
                            <v-icon color="amber">mdi-alert</v-icon>
                            Esta CLABE interbancaria es DESECHABLE, única y exclusiva para esta transacción, favor de no
                            compartirla, ni guardarla en la App de tu banco. Al terminar la operación se INVALIDARÁ y NO
                            se podrá usar más. Si la vuelves a usar tu dinero se podría perder.
                            <!-- por lo que
                            recomendamos hacer el pago a mas tardar 24 horas
                            antes de que comience el evento.-->
                          </p>
                          <!--<p class="subtitle-1">Los pagos en oxxo se ven reflejados 24 horas después de haber realizado el pago, por lo que recomendamos hacer el pago, a mas tardar 24 horas antes de que comience el evento.</p>-->
                        </v-col>

                        <v-col cols="12" class="pt-0 mt-5" v-if="user.role != 'MEMBER'">
                          <v-row justify="center">
                            <v-col cols="12">
                              <h3 class="title font-weight-bold">
                                Información del comprador
                              </h3>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field label="Nombre(s)" v-model="order.paymentInformation.name" required
                                prepend-inner-icon="fas fa-user" filled rounded />
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field label="Apellidos" v-model="order.paymentInformation.lastName" required
                                prepend-inner-icon="fas fa-user" filled rounded />
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field label="E-mail" v-model="order.paymentInformation.email" required
                                prepend-inner-icon="fas fa-envelope" filled rounded />
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col v-if="order.paymentInformation.paymentMethod ===
                          'CREDIT_CARD'
                          " cols="12" class="pt-0 mt-5">
                          <v-row justify="center">
                            <v-col cols="12">
                              <h3 class="title font-weight-bold">
                                Información de la tarjeta
                              </h3>
                            </v-col>
                            <v-col cols="12" md="12" sm="6">
                              <v-text-field v-model="order.paymentInformation.cardName" type="text" name="name"
                                label="Nombre(s) del titular" required prepend-inner-icon="fas fa-user" filled rounded />
                            </v-col>
                            <v-col cols="12">
                              <v-stripe-card v-model="source" :api-key="apiKey" :stripeAccount="stripeAccount"
                                :hidePostalCode="true" :key="stripeComponentKey"
                                :disabled="!order.paymentInformation.cardName" ref="stripeRef" />
                            </v-col>
                            <v-col v-if="false" cols="12" class="pt-0">
                              <v-switch label="Quiero pagar con meses sin intereses." :color="msConfig.primaryColor"
                                hide-details class="mt-0"></v-switch>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col v-if="installments.plans.length > 0 &&
                          order.paymentInformation.paymentMethod ===
                          'CREDIT_CARD'
                          " cols="12" class="pt-0 mt-3">
                          <v-row justiify="center">
                            <v-col cols="12" md="12" sm="6">
                              <h3 class="title font-weight-bold">
                                Meses sin intereses
                              </h3>
                            </v-col>
                            <v-col cols="12">
                              <v-card>
                                <v-list>
                                  <v-list-item-group v-model="installments.selected">
                                    <v-list-item v-for="(item, i) in installments.plans" :key="i" :value="item">
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          {{ item.count }}
                                          {{
                                            item.interval === "month"
                                            ? "meses"
                                            : "N/A"
                                          }}
                                        </v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-list-item-group>
                                </v-list>
                              </v-card>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="12" class="pb-0">
                          <h2 class="title font-weight-bold">Facturación</h2>
                        </v-col>
                        <v-col cols="12" class="d-flex">
                          <v-checkbox hide-details @click="declineBill = false" class="mr-4" v-model="acceptBill"
                            :color="msConfig.active">
                            <template v-slot:label>
                              <span class="d-flex align-center pa-0">Quiero facturar mi compra
                              </span>
                            </template>
                          </v-checkbox>
                          <v-checkbox hide-details class="mr-3" @click="acceptBill = false" v-model="declineBill"
                            :color="msConfig.active">
                            <template v-slot:label>
                              <span class="d-flex align-center pa-0">No quiero facturar mi compra
                              </span>
                            </template>
                          </v-checkbox>
                        </v-col>
                        <v-col cols="12" class="py-0 d-flex">
                          <p v-if="acceptBill">
                            <span
                              v-if="['62b622c66a7441ae20159d7d', '6297b0fec7bf8b6f826313be'].includes(organizationId)">
                              <v-icon color="red">mdi-alert</v-icon>
                              Tendrás hasta el final del día de hoy (11:59 pm) para generar tu factura usando el ID que
                              recibirás en el correo de confirmación.
                            </span>
                            <span v-else>
                              <b>Nota:</b> Tendrás hasta fin de mes para generar
                              tu factura usando el ID que recibirás en el correo
                              de confirmación
                            </span>
                          </p>
                          <p v-else-if="declineBill">
                            <b>Atención:</b> si eliges esta opción debes estar
                            seguro, ya que no podrás facturar esta compra en el
                            futuro.
                          </p>
                          <p v-else>
                            * Debe seleccionar una opción para continuar
                          </p>
                        </v-col>
                        <!---->

                        <v-col cols="12" class="pt-0"
                          v-if="['62b622c66a7441ae20159d7d', '6297b0fec7bf8b6f826313be'].includes(organizationId)">
                          <v-checkbox v-model="billingPolitics.terms" :color="msConfig.primaryColor" hide-details>
                            <template v-slot:label>
                              <span>
                                Acepto las&nbsp;
                                <router-link to="/politicas-de-facturacion" v-slot="{ href }">
                                  <a :href="href" target="_blank" @click.stop>Políticas de Facturación.
                                  </a>
                                </router-link>

                              </span>
                            </template>
                          </v-checkbox>
                        </v-col>



                        <!---->


                        <v-col cols="12" class="pt-0">
                          <v-checkbox v-model="termsOfUSe.terms" :color="msConfig.primaryColor" hide-details>
                            <template v-slot:label>
                              <span>
                                Acepto los&nbsp;
                                <router-link to="/terminos-y-condiciones" v-slot="{ href }">
                                  <a :href="href" target="_blank" @click.stop>Términos y Condiciones
                                  </a>
                                </router-link>
                                &nbsp;y el&nbsp;
                                <router-link to="/aviso-de-privacidad" v-slot="{ href }">
                                  <a :href="href" target="_blank" @click.stop>Aviso de Privacidad.</a>
                                </router-link>
                              </span>
                            </template>
                          </v-checkbox>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="6" class="my-7">
                      <v-row>
                        <v-btn class="mx-1" color="primary" text rounded @click="currentStep--">
                          <span class="font-weight-bold">Regresar</span>
                        </v-btn>
                        <v-btn class="mx-1" :color="msConfig.buttonColor" :style="`color: ${msConfig.textButtonColor}`"
                          rounded :loading="loaderContinueButton" @click="executePaymentAction"
                          :disabled="disableContinueBuyButton">
                          <span class="font-weight-bold">Continuar</span>
                        </v-btn>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="6" class="d-flex justify-end align-end pb-7">
                      <p class="headline mb-0">
                        <span class="font-weight-bold">Total: </span>{{
                          totalPurchaseWithDiscount.number == 0
                          ? "Gratis"
                          : totalPurchaseWithDiscount.string
                        }}
                      </p>
                    </v-col>
                  </v-row>
                </v-col>
                <!--FIN de Paso 3 de Checkout-->
                <!--INICIO de Paso 4 de Checkout-->
                <v-col v-if="currentStep === 4" cols="12">
                  <v-row>
                    <v-col cols="12" class="pb-0 pt-10">
                      <p class="subtitle-1 mb-7">
                        <span class="font-weight-bold mr-4">Paso {{ user.role === "MEMBER" ? 3 : 4 }} de
                          {{ user.role === "MEMBER" ? 3 : 4 }} </span><span>Revisa los datos de tu compra</span>
                      </p>
                    </v-col>
                    <v-col cols="12" class="pt-0">
                      <p class="headline font-weight-bold">Resumen</p>
                    </v-col>
                    <v-col v-if="order.itemId" cols="12">
                      <v-row class="text-center" justify="center">
                        <v-col cols="6" sm="3">
                          <h2 class="title font-weight-bold">
                            Nivel de Membresía
                          </h2>
                        </v-col>
                        <v-col cols="6" sm="3">
                          <h2 class="title font-weight-bold">Precio U.</h2>
                        </v-col>
                        <v-col cols="6" sm="3">
                          <h2 class="title font-weight-bold">Subtotal</h2>
                        </v-col>
                        <v-col cols="6" sm="3">
                          <h2 class="title font-weight-bold">Facturación</h2>
                        </v-col>
                      </v-row>
                      <v-row class="text-center" justify="center">
                        <v-col cols="6" sm="3">
                          <p class="subtitle-1 mb-0">
                            {{ affiliationLevels.selected.name }}
                          </p>
                        </v-col>
                        <v-col cols="6" sm="3">
                          <p class="subtitle-1 mb-0">
                            {{ affiliationLevels.selected.formattedPrice }}
                          </p>
                        </v-col>
                        <v-col cols="6" sm="3">
                          <p class="subtitle-1 mb-0">
                            {{ totalPurchase.string }}
                          </p>
                        </v-col>
                        <v-col cols="6" sm="3">
                          <p class="subtitle-1 mb-0">
                            {{
                              order.paymentInformation.needsInvoice
                              ? "Si"
                              : "No"
                            }}
                          </p>
                        </v-col>
                        <v-col cols="12">
                          <v-divider></v-divider>
                        </v-col>
                      </v-row>
                      <v-row v-if="order.coupon.id" class="text-center" align="center" justify="center">
                        <v-col cols="6" sm="3">
                          <h2 class="title font-weight-bold">Cupón Aplicado</h2>
                        </v-col>
                        <v-col cols="6" sm="3">
                          <h2 class="title font-weight-bold">
                            Porcentaje de Descuento
                          </h2>
                        </v-col>
                        <v-col cols="6" sm="3">
                          <h2 class="title font-weight-bold">
                            Cantidad de Descuento
                          </h2>
                        </v-col>
                      </v-row>
                      <v-row v-if="order.coupon.id" class="text-center" justify="center">
                        <v-col cols="6" sm="3">
                          <p class="subtitle-1 mb-0">
                            {{ order.coupon.code }}
                          </p>
                        </v-col>
                        <v-col cols="6" sm="3">
                          <p class="subtitle-1 mb-0">
                            {{ order.coupon.percentOff }}%
                          </p>
                        </v-col>
                        <v-col cols="6" sm="3">
                          <p class="subtitle-1 mb-0">
                            {{ discount.string }}
                          </p>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="12" md="6" class="my-9">
                      <v-row>
                        <v-btn class="mx-1" color="primary" text rounded @click="decreaseStep"><span
                            class="font-weight-bold">Regresar</span></v-btn>
                        <v-btn class="mx-1" :color="msConfig.buttonColor" :style="`color: ${msConfig.textButtonColor}`"
                          rounded @click="finishBuying">
                          <span class="font-weight-bold">Finalizar</span>
                        </v-btn>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="6" class="d-flex justify-end align-end pb-7">
                      <p class="headline mb-0">
                        <span class="font-weight-bold">Total: </span>{{
                          totalPurchaseWithDiscount.number == 0
                          ? "Gratis"
                          : totalPurchaseWithDiscount.string
                        }}
                      </p>
                    </v-col>
                  </v-row>
                </v-col>
                <!--FIN de Paso 4 de Checkout-->
                <!--INICIO de última sección del Checkout-->
                <v-col v-if="currentStep === 5" cols="12" class="pb-10">
                  <v-row>
                    <v-col cols="12" class="text-center">
                      <v-row justify="center" class="mb-5">
                        <v-icon :style="getSizeFinalIcon">mdi-email-newsletter</v-icon>
                      </v-row>
                    </v-col>
                    <v-col cols="12" class="text-center pt-0">
                      <p class="headline font-weight-bold text-uppercase">
                        ¡Muchas gracias!
                      </p>
                      <!-- Para pagos con tarjeta, que usaron cupón/cortesía del 100% o el boleto costaba $0.00 -->
                      <p v-if="totalPurchase.number === 0 ||
                        order.paymentInformation.paymentMethod ===
                        paymentMethodsKeys.CreditCard
                        " class="subtitle-1 mb-3">
                        El registro se ha realizado exitosamente, consulte su
                        correo electrónico para obtener sus acceso(s).
                      </p>
                      <!-- Para referencias de SPEI y OXXO -->
                      <p v-else class="subtitle-1 mb-3">
                        El registro se ha realizado exitosamente, consulte su
                        correo electrónico para obtener su referencia de pago.
                      </p>
                      <!-- Para cualquier tipo de registro -->
                      <p class="subtitle-1 mb-3" style="color: #17c6e9">
                        En ocasiones tu configuración de seguridad envía algunos
                        correos a SPAM, no olvides revisar ahí.
                      </p>
                      <p v-if="order.paymentInformation.needsInvoice" class="subtitle-1">
                        El id de tu orden es el siguiente <b>{{ order.id }}</b>, te recomendamos guardarlo, ya que te
                        servirá para
                        generar la factura de tu compra.
                      </p>
                      <div v-if="order.coupon.percentOff != 100 &&
                        !paymentModality &&
                        order.paymentInformation.needsInvoice
                        ">
                        <v-btn large class="mx-1 font-weight-bold my-2 mb-6 pa-5" :color="msConfig.buttonColor"
                          :style="`color: ${msConfig.textButtonColor}`" target="_blank"
                          :href="`/facturacion-cliente/${order.id}`" rounded>
                          Generar Factura
                        </v-btn>
                      </div>
                      <p v-if="['62b622c66a7441ae20159d7d', '6297b0fec7bf8b6f826313be'].includes(organizationId) &&
                        (order.coupon.percentOff != 100 &&
                          !paymentModality &&
                          order.paymentInformation.needsInvoice)" class="subtitle-1 mb-3">
                        <v-icon color="red">mdi-alert</v-icon>
                        Tendrás hasta el final del día de hoy (11:59 pm) para generar tu factura usando el ID que
                        recibirás en el correo de confirmación.
                      </p>
                      <p class="headline text-uppercase font-weight-bold"
                        :class="order.total === '0' ? 'text-center' : ''">
                        Resumen
                      </p>
                      <h4 v-if="order.paymentInformation.paymentMethod ===
                        paymentMethodsKeys.BankTransfer
                        " style="color: #ff0000">
                        Recuerda que tienes hasta las {{ dateExpireOrder }} para
                        realizar tu pago.
                      </h4>
                    </v-col>
                    <v-col v-if="order.itemId" cols="12">
                      <v-row class="text-center" justify="center">
                        <v-col cols="6" sm="3">
                          <h2 class="title font-weight-bold">
                            Nivel de Membresía
                          </h2>
                        </v-col>
                        <v-col cols="6" sm="3">
                          <h2 class="title font-weight-bold">Precio U.</h2>
                        </v-col>
                        <v-col cols="6" sm="3">
                          <h2 class="title font-weight-bold">Subtotal</h2>
                        </v-col>
                        <v-col cols="6" sm="3">
                          <h2 class="title font-weight-bold">Facturación</h2>
                        </v-col>
                      </v-row>
                      <v-row class="text-center" justify="center">
                        <v-col cols="6" sm="3">
                          <p class="subtitle-1 mb-0">
                            {{ affiliationLevels.selected.name }}
                          </p>
                        </v-col>
                        <v-col cols="6" sm="3">
                          <p class="subtitle-1 mb-0">
                            {{ affiliationLevels.selected.formattedPrice }}
                          </p>
                        </v-col>
                        <v-col cols="6" sm="3">
                          <p class="subtitle-1 mb-0">
                            {{ totalPurchase.string }}
                          </p>
                        </v-col>
                        <v-col cols="6" sm="3">
                          <p class="subtitle-1 mb-0">
                            {{
                              order.paymentInformation.needsInvoice
                              ? "Si"
                              : "No"
                            }}
                          </p>
                        </v-col>
                      </v-row>
                      <v-row v-if="order.coupon.id" class="text-center" align="center" justify="start">
                        <v-col cols="12">
                          <v-divider />
                        </v-col>
                        <v-col cols="6" sm="3">
                          <h2 class="title font-weight-bold">Cupón Aplicado</h2>
                        </v-col>
                        <v-col cols="6" sm="3">
                          <h2 class="title font-weight-bold">
                            Porcentaje de Descuento
                          </h2>
                        </v-col>
                        <v-col cols="6" sm="3">
                          <h2 class="title font-weight-bold">
                            Cantidad de Descuento
                          </h2>
                        </v-col>
                      </v-row>
                      <v-row v-if="order.coupon.id" class="text-center" justify="start">
                        <v-col cols="6" sm="3">
                          <p class="subtitle-1 mb-0">
                            {{ order.coupon.code }}
                          </p>
                        </v-col>
                        <v-col cols="6" sm="3">
                          <p class="subtitle-1 mb-0">
                            {{ order.coupon.percentOff }}%
                          </p>
                        </v-col>
                        <v-col cols="6" sm="3">
                          <p class="subtitle-1 mb-0">
                            {{ discount.string }}
                          </p>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
                <!--FIN de última sección del Checkout-->
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="my-10">
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12" class="d-flex justify-center">
        <v-img max-width="150" height="auto" src="@/assets/images/weChamber/poweredby.svg"></v-img>
      </v-col>
    </v-row>
    <!---------------------------------------DIALOGS DE CHECKOUT--------------------------------------------->
    <!--Dialogs de facturación-->
    <v-dialog v-model="facturaDialog" persistent max-width="400">
      <v-card>
        <v-card-text class="px-1 rounded-xl">
          <v-container>
            <v-row justify="center">
              <v-col cols="12" class="pt-8">
                <v-row justify="center">
                  <v-icon :color="msConfig.primaryColor" :style="getBigSize">fas fa-question-circle</v-icon>
                </v-row>
              </v-col>
              <v-col cols="12" class="text-center">
                <h2 class="headline font-weight-bold">
                  ¿Cómo obtengo mi factura?
                </h2>
                <p>
                  Solo debes registrar tus datos fiscales en este apartado.
                  Recibirás de forma automática tu factura vía correo
                  electrónico una vez finalizada tu compra.
                </p>
                <v-row justify="center" class="pt-3">
                  <v-btn color="primary" @click="(factura = true), (facturaDialog = false)" rounded>Aceptar</v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :timeout="5000" :color="'error'" right top>
      <v-icon color="white">mdi-domain</v-icon>

      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <modal-template :open="isOpenErrorCardModal" width="600" :secondaryBtnProps="{
      show: true,
      text: 'Cerrar',
      textColor: 'white',
      color: 'accent',
    }" @close="closeErrorCardModal">
      <template #content>
        <v-row justify="center">
          <v-col cols="10" md="7" class="mt-8 d-flex justify-center">
            <v-img class="" src="@/assets/images/weChamber/logo-WC-microsite.svg" max-width="60" height="auto"
              contain></v-img>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="mt-2">
            <p class="text-center font-weight-bold title mb-0">
              No se ha podido finalizar el pago
            </p>
          </v-col>
          <v-col cols="12" class="py-0">
            <p class="subtitle-1 text-center mb-0">{{ errorMessage }}</p>
          </v-col>
          <v-col cols="12" class="pt-0">
            <p class="subtitle-1 text-center mb-0 secondary--text font-weight-bold">
              {{ errorCode }}
            </p>
          </v-col>
        </v-row>
        <v-row v-if="errorCode != ''">
          <v-col cols="12">
            <p class="subtitle-1 text-center">
              Para más información sobre el código de error, puede ingresar al
              siguiente link:
              <a href="https://stripe.com/docs/error-codes" target="_blank">Códigos de error de pago con tarjetas</a>
            </p>
          </v-col>
        </v-row>
      </template>
    </modal-template>
  </v-container>
</template>
<script>
import VStripeCard from "@/components/v-stripe-elements/lib/VStripeCard";
import { validations } from "@/share/validations";
import Endpoints from "@/share/Endpoints";
import { formatPrice } from "@/utils";
import { mapMutations, mapState, mapActions } from "vuex";
import CardErrors from "@/share/cardErrorCodes";
import ModalTemplate from "@/components/shared/ModalTemplate";
import moment from "moment";
import BillingTerms from "../../components/dashboard/billing/billingTerms.vue";

export default {
  components: {
    VStripeCard,
    ModalTemplate,
    BillingTerms,
  },
  props: ["affiliationId", "membershipId"],
  data() {
    return {
      errorMessage: "",
      errorCode: "",
      acceptBill: false,
      declineBill: false,
      hasStripeConfig: false,
      couponApplied: false,
      loaderContinueButton: false,
      source: null,
      affiliationLevels: {
        selected: {
          id: null,
          name: "",
          price: null,
          formattedPrice: "",
        },
        levels: [],
      },
      errors: {
        coupon: {
          error: false,
          message: "",
        },
      },
      success: {
        coupon: {
          success: false,
          message: "",
        },
      },
      currentStep: 1,
      coupon: "",
      panel: [0],
      billingPolitics: {
        terms: false,
        receiveInfo: false
      },

      termsOfUSe: {
        terms: false,
        receiveInfo: false,
      },
      fieldsValidations: {
        name: validations.nameValidation({
          required: true,
        }),
        lastName: validations.nameValidation({
          name: "apellidos",
          required: true,
        }),
        email: validations.emailValidation({
          required: true,
        }),
        required: validations.requiredMValidation(),
      },
      factura: false,
      facturaDialog: false,
      ticketsToBuy: 0,
      maxTicketsUserCanBuy: 5,
      msConfig: {
        banner: "",
        primaryColor: "black",
        buttonColor: "accent",
        textButtonColor: "white",
      },
      tickets: [],
      paymentMethods: [],
      organizationId: '',
      paymentMethodsKeys: {
        Cash: "CASH",
        CreditCard: "CREDIT_CARD",
        BankTransfer: "BANK_TRANSFER",
      },
      paymentLabels: {
        CASH: "Efectivo (Oxxo)",
        CREDIT_CARD: "Tarjeta de Crédito / Débito",
        BANK_TRANSFER: "Transferencia Bancaria SPEI",
      },
      installments: {
        selected: null,
        plans: [],
      },
      affiliateInformation: {
        name: "",
        lastName: "",
        email: "",
        confirmEmail: "",
        registrationFields: {},
      },
      // Objecto que guardará los datos de la transacción
      order: {
        id: "",
        coupon: {
          percentOff: 0,
        },
        itemId: null,
        paymentInformation: {
          name: "",
          lastName: "",
          email: "",
          paymentMethod: null,
          needsInvoice: false,
          cardName: "",
        },
      },
      cardPaymentIntentId: "",
      total: 0,
      snackbar: false,
      snackbarText: "PlaceHolder",
      stripeComponentKey: 0,
      clientSecret: "",
      apiKey: process.env.VUE_APP_STRIPE_API_KEY,
      isOpenErrorCardModal: false,
      stripeAccount: "",
    };
  },
  watch: {
    currentStep(current) {
      const paymentStep = 3;
      if (current === paymentStep) {
        const { name, lastName, email } = this.order.paymentInformation;
        !name &&
          (this.order.paymentInformation.name = this.affiliateInformation.name);
        !lastName &&
          (this.order.paymentInformation.lastName =
            this.affiliateInformation.lastName);
        !email &&
          (this.order.paymentInformation.email =
            this.affiliateInformation.email);

        if (this.totalPurchaseWithDiscount.number === 0) {
          this.increaseStep();
        }
        this.cardPaymentIntentId = "";
      }
    },
    source: async function () {
      const { card, stripe } = this.$refs.stripeRef;

      this.loaderContinueButton = true;
      try {
        const { paymentMethod } = await stripe.createPaymentMethod({
          type: "card",
          card,
          billing_details: {
            name: this.order.paymentInformation.cardName,
            email: this.order.paymentInformation.email,
          },
        });
        const { data } = await this.axios({
          method: "post",
          url: Endpoints.cardPayementIntent,
          data: {
            paymentMethod: { id: paymentMethod.id, total: this.total },
            orderId: this.order.id,
          },
        });
        this.installments.plans = data.paymentIntent.availablePlans;
        this.cardPaymentIntentId = data.paymentIntent.id;
      } catch (error) {
        console.log(error);
      } finally {
        this.loaderContinueButton = false;
      }
    },
  },
  async mounted() {
    if (this.user.role === "MEMBER") {
      this.order.paymentInformation.name = this.user.name;
      this.order.paymentInformation.lastName = this.user.firstLastname;
      this.order.paymentInformation.email = this.user.email;

      this.affiliateInformation.name = this.user.name;
      this.affiliateInformation.lastName =
        this.user.lastName || this.user.firstLastname;
      this.affiliateInformation.email = this.user.email;
      this.affiliateInformation.confirmEmail = this.user.email;
    }
  },
  created() {
    this.createOrder();
    this.getAffiliationInfo();
  },
  methods: {
    ...mapMutations("loader", ["loading", "loaded"]),
    ...mapActions("user", ["realoadUserData"]),
    ...mapMutations("notification", ["show"]),
    reload() {
      this.realoadUserData({ email: this.user.email });
    },

    getValueInOrgData(arrayObject, id) {
      let findIndex = arrayObject.findIndex((item) => item.id == id);
      console.log(arrayObject[findIndex]);
      return arrayObject[findIndex];
    },

    async getMethodPaymentActive(userID) {
      const response = await this.axios.get(
        Endpoints.excecuteGetMethodPayment.replace(/:userID/, userID)
      );
      const { data } = response;
      this.hasStripeConfig = this.getValueInOrgData(
        data.methods,
        "bank_transfer_payments"
      ).active;
    },

    async increaseStep() {
      if (this.currentStep === 1 && this.user.role == "MEMBER") {
        this.currentStep = 3;
      } else {
        this.currentStep++;
      }
      // if(this.currentStep === 5){
      //   setTimeout(() => {
      //     if(this.membershipId){
      //       this.$router.push(`/member/newAffiliate/2/${this.membershipId}`)
      //     }else{
      //       this.realoadUserData({email: this.user.email})
      //       this.$router.push(`/member/renew/2/${this.user._id}`)
      //     }
      //   }, 10000);
      // }
    },
    closeErrorCardModal() {
      this.isOpenErrorCardModal = false;
      this.errorMessage = "";
      this.errorCode = "";
    },
    decreaseStep() {
      if (
        this.currentStep == 4 &&
        this.totalPurchaseWithDiscount.number === 0
      ) {
        this.currentStep -= 2;
      } else {
        this.currentStep--;
      }
    },
    async createOrder() {
      try {
        this.loading();
        const payload = {
          order: {
            affiliation: this.affiliationId,
          },
        };
        if (this.user.role == "MEMBER") {
          payload.order["type"] =
            this.affiliationId === this.user.affiliation.national._id
              ? "HEAD_OFFICE"
              : "BRANCH";
          let memberID = this.membershipId ? this.membershipId : this.user._id;
          payload.order["member"] = memberID;
        }

        const response = await this.axios.post(
          Endpoints.checkoutAffiliationCreateOrder,
          payload
        );

        if (response.status === 200) {
          const { id } = response.data.order;
          this.order.id = id;
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        this.loaded();
      }
    },
    formattedAffiliationLevels(affiliationLevels) {
      return affiliationLevels.map((level) => ({
        ...level,
        formattedPrice: formatPrice(level.price),
      }));
    },
    async getAffiliationInfo() {
      try {
        this.loading();
        let url = Endpoints.checkoutAffiliationGetInfo.replace(
          /:affiliationId/,
          this.affiliationId
        );
        if (this.membershipId) url = url + "/true"; ///valida al membership
        const response = await this.axios.get(url);
        if (response.status === 200) {
          const {
            banner,
            micrositeConfiguration: {
              primaryColor,
              buttonColor,
              textButtonColor,
            },
            affiliationTypes,
            paymentMethods,
            organization: { stripeConnectId, id },
          } = response.data.checkoutInformation;
          this.msConfig = {
            banner,
            primaryColor,
            buttonColor,
            textButtonColor,
          };
          this.affiliationLevels.levels =
            this.formattedAffiliationLevels(affiliationTypes);
          this.paymentMethods = paymentMethods;
          this.organizationId = id
          this.stripeAccount = stripeConnectId;
          await this.getMethodPaymentActive(
            response.data.checkoutInformation.organization._id
          );
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        this.loaded();
      }
    },
    async addItemToOrder(levelId) {
      const payload = {
        item: {
          order: this.order.id,
          affiliationType: levelId,
        },
      };
      if (this.user.role == "MEMBER") {
        payload.item["type"] =
          this.affiliationId === this.user.affiliation.national._id
            ? "HEAD_OFFICE"
            : "BRANCH";
        let memberID = this.membershipId ? this.membershipId : this.user._id;
        payload.item["member"] = memberID;
      }
      let url = Endpoints.checkoutAffiliationItemManagement;
      if (this.membershipId) url = url + "/true"; ///valida al membership

      const response = await this.axios.post(url, payload);

      if (response.status === 200) {
        const { affiliationTypes, order } = response.data;
        this.total = order.total;
        const activeLevels = affiliationTypes.filter(
          (level) => level.status === "ACTIVE"
        );
        this.affiliationLevels.levels =
          this.formattedAffiliationLevels(activeLevels);
        this.order.itemId = order.items[0]._id;
        if (this.user.role == "MEMBER") {
          await this.setAffiliatiteInformationSave();
        }
      }
    },
    async removeItemFromOrder(levelId) {
      const payload = {
        item: {
          order: this.order.id,
          affiliationType: levelId,
        },
      };
      const response = await this.axios.delete(
        Endpoints.checkoutAffiliationItemManagement,
        { data: payload }
      );
    },
    async handleRadioChange(level) {
      try {
        this.loading();

        const { id } = this.affiliationLevels.selected;
        id && (await this.removeItemFromOrder(id));
        await this.addItemToOrder(level.id);
        this.affiliationLevels.selected = level;
      } catch (error) {
        console.log("error", error);
      } finally {
        this.loaded();
      }
    },
    async addCoupon() {
      try {
        this.loading();
        const payload = {
          coupon: {
            affiliation: this.affiliationId,
            order: this.order.id,
            code: this.coupon,
          },
        };
        const response = await this.axios.post(
          Endpoints.affiliationCouponsValidate,
          payload
        );
        if (response.status === 200) {
          this.couponApplied = true;
          const { coupon } = response.data;

          this.order.coupon = coupon;
          this.setErrorToInitial("coupon");
          this.success.coupon = {
            success: true,
            message: `${coupon.percentOff}% de descuento, se aplicará al finalizar la compra.`,
          };
        }
      } catch (error) {
        const response = error.response;
        this.setSuccessToInitial("coupon");
        this.errors.coupon = {
          error: true,
          message:
            response && response.data && response.data.error
              ? response.data.error.message
              : "El cupón no es válido",
        };
      } finally {
        this.loaded();
      }
    },
    async setAffiliateInformation() {
      if (!this.$refs.affiliationLevelForm.validate()) return;

      await this.setAffiliatiteInformationSave();

      if (this.totalPurchaseWithDiscount.number === 0) {
        this.currentStep += 2;
      } else {
        this.currentStep += 1;
      }
    },
    async setAffiliatiteInformationSave() {
      const { name, lastName, email, registrationFields } =
        this.affiliateInformation;
      const payload = {
        order: {
          id: this.order.id,
          items: [
            {
              _id: this.order.itemId,
              affiliateInformation: {
                ...registrationFields,
                name,
                lastName,
                email,
              },
            },
          ],
        },
      };
      try {
        await this.axios.post(
          Endpoints.checkoutAffiliationSetAffiliateInfo,
          payload
        );
      } catch (error) {
        console.log(error);
      }
    },
    setErrorToInitial(errorKey) {
      if (this.errors[errorKey].error) {
        this.errors[errorKey] = {
          error: false,
          message: "",
        };
      }
    },
    setSuccessToInitial(successKey) {
      if (this.success[successKey].success) {
        this.success[successKey] = {
          success: true,
          message: "",
        };
      }
    },
    async finishBuying() {
      if (this.totalPurchaseWithDiscount.number === 0) {
        try {
          this.loading();
          await this.axios.post(Endpoints.checkoutAffiliationFreeOrder, {
            order: {
              orderId: this.order.id,
              affiliationId: this.affiliationId,
              paymentInformation: {
                name:
                  this.affiliateInformation.name +
                  " " +
                  this.affiliateInformation.lastName,
                email: this.affiliateInformation.email,
              },
            },
          });
        } catch (error) {
          console.log("error", error);
        } finally {
          this.increaseStep();
          this.loaded();
        }
      } else {
        try {
          const { Cash, CreditCard, BankTransfer } = this.paymentMethodsKeys;
          const { paymentMethod } = this.order.paymentInformation;
          this.loading();

          const cases = {
            [Cash]: this.executeCashPayment,
            [CreditCard]: this.executeCardPayment,
            [BankTransfer]: this.executeBankTransferPayment,
          };

          cases[paymentMethod] && (await cases[paymentMethod]());
          this.increaseStep();
        } catch (error) {
          console.log("error", error);
        } finally {
          this.loaded();
        }
      }
    },
    async executeCardPayment() {
      try {
        await this.axios({
          method: "post",
          url: Endpoints.executeCardPayement,
          json: true,
          data: {
            payment: {
              paymentIntentId: this.cardPaymentIntentId,
              selectedPlan: this.installments.selected,
              connectedAccount: this.stripeAccount,
            },
          },
        });

        await this.setOrderPaymentInformation();
      } catch (error) {
        this.errorMessage = "";
        if (error.declineCode && CardErrors[error.declineCode]) {
          this.errorMessage = CardErrors[error.declineCode];
          this.errorCode = error.declineCode;
        } else if (error.code && CardErrors[error.code]) {
          this.errorMessage = CardErrors[error.code];
          this.errorCode = error.code;
        } else {
          this.errorMessage = "El pago falló porque el banco emisor lo rechazó. Comunicate con el banco emisor para obtener más información.";
          this.errorCode = "";
        }
        this.isOpenErrorCardModal = true;
        this.currentStep--;
      }
    },
    async executePaymentAction() {
      this.loading();
      await this.setOrderPaymentInformation();
      this.increaseStep();
      this.loaded();
    },
    async executeCashPayment() {
      this.loading();

      let response = await this.axios({
        method: "post",
        url: Endpoints.cashPaymentIntent,
        json: true,
        data: {
          paymentMethod: { total: this.totalPurchaseWithDiscount.number },
          orderId: this.order.id,
        },
      }).catch((error) => {
        this.handleError(error.data.error.message);
      });

      this.clientSecret = response.data.paymentIntent.clientSecret;

      const stripe = Stripe(process.env.VUE_APP_STRIPE_API_KEY, {
        stripeAccount: this.stripeAccount,
      });

      const result = await stripe.confirmOxxoPayment(this.clientSecret, {
        payment_method: {
          billing_details: {
            name: `${this.order.paymentInformation.name} ${this.order.paymentInformation.lastName}`,
            email: this.order.paymentInformation.email,
          },
        },
      });
      if (result.error) {
        console.log("MENSAJE DE ERROR");
        console.log(result.error);
        this.show({
          text: "¡No se ha podido realizar la transacción!",
          color: "error",
        });
      } else {
        console.log("stripe result", result);
        await this.axios({
          method: "PUT",
          url: `/orders/set-payment-reference/${this.order.id}`,
          data: {
            paymentReference:
              result.paymentIntent.next_action.oxxo_display_details
                .hosted_voucher_url,
          },
        });
        this.show({
          text: "Registro realizado correctamente",
          color: "primary",
        });
      }
      this.loaded();
    },
    async executeBankTransferPayment() {
      try {
        this.loading();

        //await this.setOrderPaymentInformation();

        let response = await this.axios({
          method: "post",
          url: Endpoints.bankTransferPaymentIntent,
          json: true,
          data: {
            paymentMethod: { total: this.total },
            customer: {
              name: `${this.order.paymentInformation.name} ${this.order.paymentInformation.lastName}`,
              email: this.order.paymentInformation.email,
            },
            orderId: this.order.id,
          },
        });

        this.clientSecret = response.data.paymentIntent.clientSecret;

        const stripe = Stripe(process.env.VUE_APP_STRIPE_API_KEY, {
          stripeAccount: this.stripeAccount,
          apiVersion: "2020-08-27",
          betas: ["customer_balance_beta_1"],
        });

        const result = await stripe.confirmCustomerBalancePayment(
          this.clientSecret,
          {
            payment_method: {
              billing_details: {
                name: `${this.order.paymentInformation.name} ${this.order.paymentInformation.lastName}`,
                email: this.order.paymentInformation.email,
              },
              customer_balance: {},
            },
            payment_method_options: {
              customer_balance: {
                funding_type: "bank_transfer",
                bank_transfer: {
                  type: "mx_bank_account",
                },
              },
            },
          },
          {
            handleActions: true,
          }
        );

        if (result.error) {
          this.show({
            text: "¡No se ha podido realizar la transacción!",
            color: "error",
          });
        } else {
          await this.axios({
            method: "PUT",
            url: `/orders/set-payment-reference/${this.order.id}`,
            data: {
              paymentReference:
                result.paymentIntent.next_action.display_bank_transfer_instructions
                  .hosted_instructions_url,
            },
          });
          this.show({
            text: "Registro realizado correctamente",
            color: "primary",
          });
        }
      } catch (error) {
        console.log("error", error);
        this.show({
          text:
            error.response.data && error.response.data.error
              ? error.response.data.error
              : "¡No se ha podido realizar la transacción!",
          color: "error",
        });
      }
    },
    async setOrderPaymentInformation() {
      this.order.paymentInformation.needsInvoice = this.acceptBill;
      console.log(this.order.paymentInformation.needsInvoice);
      try {
        await this.axios({
          method: "post",
          url: Endpoints.checkoutSetPaymentInformation,
          json: true,
          // needsInvoice si es true no se genera a publico general
          data: {
            order: {
              id: this.order.id,
              paymentInformation: this.order.paymentInformation,
            },
          },
        });
      } catch (error) {
        console.log(error);
      } finally {
      }
    },
    paymentSelection(toggle, paymentName, active) {
      if (paymentName == "CREDIT_CARD") {
        this.cardPaymentIntentId = "";
      }
      this.order.paymentInformation.paymentMethod = !active
        ? paymentName
        : null;
      toggle();
    },
    handleError(message) {
      this.loaded();
    },
    routeValidation(route) {
      const regexpresion = new RegExp("ampi", "i");
      const regexpresion1 = new RegExp(
        "Asociación Mexicana de Profesionales Inmobiliarios",
        "i"
      );
      return regexpresion.test(route) || regexpresion1.test(route);
    },
    formatEmail: (email) => email.toLowerCase().trim(),
  },
  computed: {
    ...mapState("user", ["user"]),
    activePaymentMethods() {
      // validates if payment methods are true
      let paymentMethodsActive = this.paymentMethods
        .map((method) =>
          method.name == "BANK_TRANSFER"
            ? method.active && this.hasStripeConfig
            : method.active
        )
        .includes(true);
      this.paymentMethods = this.paymentMethods.filter(
        (method) => method.active == true
      );
      return paymentMethodsActive;
    },
    isAffiliationLevelSelected() {
      return !!this.affiliationLevels.selected.id;
    },
    dateExpireOrder() {
      let expireIn = moment().set("hour", 72);
      return `${expireIn.format("h:mm")} hrs del dia ${expireIn.get(
        "date"
      )} de ${expireIn.format("MMMM")}`;
    },
    discount() {
      const total = this.totalPurchase.number;
      const discount = total * (this.order.coupon.percentOff / 100);
      return {
        string: formatPrice(discount),
        number: discount,
      };
    },
    totalPurchase() {
      const level = this.affiliationLevels.levels.find(
        (lvl) => lvl.id === this.affiliationLevels.selected.id
      );
      return {
        string: level?.formattedPrice || formatPrice(0),
        number: level?.price || 0,
      };
    },
    totalPurchaseWithDiscount() {
      const total = this.totalPurchase.number;
      const discount = this.discount.number;
      const totalWithDiscount = total - discount;
      return {
        string: formatPrice(totalWithDiscount),
        number: totalWithDiscount,
      };
    },
    disableContinueBuyButton() {
      const { name, lastName, email, paymentMethod } =
        this.order.paymentInformation;
      const commonRules =
        !this.termsOfUSe.terms ||
        !this.billingPolitics.terms ||
        !lastName ||
        !email ||
        !name ||
        !paymentMethod;
      const creditCardRules =
        paymentMethod === this.paymentMethodsKeys.CreditCard &&
        !this.cardPaymentIntentId;

      return (
        commonRules ||
        creditCardRules ||
        !(this.acceptBill == true || this.declineBill == true)
      );
    },
    formasDePago() {
      return [
        "01 Efectivo",
        "03 Transferencia electrónica de fondos ( Incluye SPEI)",
        "04 Tarjeta de crédito",
        "28 Tarjeta de débito",
      ];
    },
    ticketsToBuyIsZero() {
      return this.ticketsToBuy === 0;
    },
    /**
     * Función que toma el tamaño de la pantalla del dispositivo para dar tamaño a los iconos (v-dialog de facturación).
     */
    getBigSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "font-size:2.5em";
        case "sm":
          return "font-size:2.7em";
        case "md":
          return "font-size:2.7em";
        case "lg":
          return "font-size:3em";
        case "xl":
          return "font-size:3.2em";
      }
    },
    /**
     * Función que toma el tamaño de la pantalla del dispositivo para dar tamaño a los iconos de métodos de pago
     */
    getSizePaymentsMethodsIcons() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "font-size:2.5em";
        case "sm":
          return "font-size:3em";
        case "md":
          return "font-size:3em";
        case "lg":
          return "font-size:2.3em";
        case "xl":
          return "font-size:2.5em";
      }
    },
    getSizeFinalIcon() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "font-size:4.5em";
        case "sm":
          return "font-size:4.5em";
        case "md":
          return "font-size:5em";
        case "lg":
          return "font-size:6.3em";
        case "xl":
          return "font-size:6.5em";
      }
    },
  },
};
</script>
<style scoped>
.access-item-border {
  border: 1.2px solid !important;
  border-color: rgb(241, 241, 241) !important;
}

.break-word {
  word-break: break-word;
}
</style>
